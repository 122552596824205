import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Header_EN from "./../locales/en/header.json";
import Footer_EN from "./../locales/en/footer.json";
import Home_EN from "./../locales/en/home.json";
import Header_VI from "./../locales/vi/header.json";
import Footer_VI from "./../locales/vi/footer.json";
import Home_VI from "./../locales/vi/home.json";
import AboutUs_VI from "./../locales/vi/aboutUs.json";
import AboutUs_EN from "./../locales/en/aboutUs.json";
import Service_VI from "./../locales/vi/service.json";
import Service_EN from "./../locales/en/service.json";
import News_VI from "./../locales/vi/news.json";
import News_EN from "./../locales/en/news.json";
import Career_VI from "./../locales/vi/career.json";
import Career_EN from "./../locales/en/career.json";
import ContactUs_VI from "./../locales/vi/contactUs.json";
import ContactUs_EN from "./../locales/en/contactUs.json";
import AboutUsDetail_VI from "./../locales/vi/aboutUsDetail.json";
import AboutUsDetail_EN from "./../locales/en/aboutUsDetail.json";
import ServiceDetail_VI from "./../locales/vi/serviceDetail.json";
import ServiceDetail_EN from "./../locales/en/serviceDetail.json";
import NewsDetail_VI from "./../locales/vi/newsDetail.json";
import NewsDetail_EN from "./../locales/en/newsDetail.json";
import NewsCategory_VI from "./../locales/vi/newsCategory.json";
import NewsCategory_EN from "./../locales/en/newsCategory.json";
import Product_VI from "./../locales/vi/product.json";
import Product_EN from "./../locales/en/product.json";

const lang = localStorage.getItem("language") || "vi";
export const locales = {
  en: "English",
  vi: "Tiếng Việt",
} as const;
export const resources = {
  en: {
    header: Header_EN,
    footer: Footer_EN,
    home: Home_EN,
    aboutUs: AboutUs_EN,
    service: Service_EN,
    news: News_EN,
    career: Career_EN,
    contactUs: ContactUs_EN,
    aboutUsDetail: AboutUsDetail_EN,
    serviceDetail: ServiceDetail_EN,
    newsDetail: NewsDetail_EN,
    newsCategory: NewsCategory_EN,
    product: Product_EN,
  },
  vi: {
    aboutUs: AboutUs_VI,
    header: Header_VI,
    footer: Footer_VI,
    home: Home_VI,
    service: Service_VI,
    news: News_VI,
    career: Career_VI,
    contactUs: ContactUs_VI,
    aboutUsDetail: AboutUsDetail_VI,
    serviceDetail: ServiceDetail_VI,
    newsDetail: NewsDetail_VI,
    newsCategory: NewsCategory_VI,
    product: Product_VI,
  },
};
export const defaultNS = "header";
i18n.use(initReactI18next).init({
  resources,
  lng: lang,
  ns: [
    "header",
    "footer",
    "home",
    "aboutUs",
    "service",
    "news",
    "career",
    "contactUs",
    "aboutUsDetail",
    "serviceDetail",
    "newsDetail",
    "newsCategory",
  ],
  defaultNS,
  fallbackLng: lang,
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
