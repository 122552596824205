import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListRespone } from "../models/commom";
import { InforServices } from "../models/inforServices";
import { InforServicesApis } from "../package/client/api/inforServicesApis";

interface InforServicesSliceState {
  loading: boolean;
  apis: ListRespone<InforServices>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: InforServicesSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as InforServices,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListInforServiceClient = createAsyncThunk(
  "InforServices/GetListInforServiceClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await InforServicesApis.GetListInforServiceClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetInforServiceByIdCategoriesService = createAsyncThunk(
  "InforServices/GetInforServiceByIdCategoriesService",
  async (params: { idCategoriesService: string }, thunkAPI) => {
    try {
      const response = await InforServicesApis.GetInforServiceByIdCategoriesService(params.idCategoriesService);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListInforServiceByIdCategoriesService = createAsyncThunk(
  "InforServices/GetListInforServiceByIdCategoriesService",
  async (params: { idCategoriesService: string; pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await InforServicesApis.GetListInforServiceByIdCategoriesService(
        params.pageSize,
        params.pageNumber,
        params.idCategoriesService
      );
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListInforServiceById = createAsyncThunk(
  "InforServices/GetListInforServiceById",
  async (params: { id: string }, thunkAPI) => {
    try {
      const response = await InforServicesApis.GetListInforServiceById(params.id);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const InforServicesSlice = createSlice({
  name: "InforServices",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListInforServiceClient
    [GetListInforServiceClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListInforServiceClient.fulfilled.type]: (state, action: PayloadAction<ListRespone<InforServices>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListInforServiceClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetInforServiceByIdCategoriesService
    [GetInforServiceByIdCategoriesService.pending.type]: (state) => {
      state.loading = true;
    },
    [GetInforServiceByIdCategoriesService.fulfilled.type]: (state, action: PayloadAction<ListRespone<InforServices>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetInforServiceByIdCategoriesService.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListInforServiceByIdCategoriesService
    [GetListInforServiceByIdCategoriesService.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListInforServiceByIdCategoriesService.fulfilled.type]: (state, action: PayloadAction<ListRespone<InforServices>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListInforServiceByIdCategoriesService.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListInforServiceById
    [GetListInforServiceById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListInforServiceById.fulfilled.type]: (state, action: PayloadAction<ListRespone<InforServices>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListInforServiceById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
  },
});

export default InforServicesSlice.reducer;
