import { CategoryAboutUs } from "../../../models/categoryAboutUs";
import { ListRespone } from "../../../models/commom";
import { axiosClient } from "./axiosClient";

export const categoryAboutUsApis = {
  GetListCategoryAboutUsClient: (pageSize: number, pageNumber: number): Promise<ListRespone<CategoryAboutUs>> => {
    const url = `/CategoryAboutUs/GetListCategoryAboutUsClient?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListCategoryAboutUsAppBar: (pageSize: number, pageNumber: number): Promise<ListRespone<CategoryAboutUs>> => {
    const url = `/CategoryAboutUs/GetListCategoryAboutUsAppBar?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
} as const;