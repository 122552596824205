import React from "react";
import "./loading.css";
export default function Loading() {
  return (
    <div className="loadingio-spinner-pulse-sakbxvum4wi">
      <div className="ldio-2lmf8usel4z">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
