import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Client } from "./Routes";


function App()
{


  const navigate = useNavigate();


  useEffect(() => window.scrollTo(0, 0), [navigate]);
  return <Client />;
}

export default App;
