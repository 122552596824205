import { Career } from "../../../models/career";
import { ListRespone } from "../../../models/commom";
import { axiosClient } from "./axiosClient";

export const CareerApis = {
  GetListCareerAdmin: (pageNumber: number, pageSize: number): Promise<ListRespone<Career>> => {
    const url = `/career/GetListCareerAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return axiosClient.get(url);
  },
  GetListCareerClient: (pageNumber: number, pageSize: number): Promise<ListRespone<Career>> => {
    const url = `/career/GetListCareerClient?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return axiosClient.get(url);
  },
  GetListCareerById: (IdCareer: string): Promise<ListRespone<Career>> => {
    const url = `/career/GetListCareerById?IdCareer=${IdCareer}`;
    return axiosClient.get(url);
  },
  InsertCareer: (career: Career): Promise<ListRespone<Career>> => {
    const url = `/career/InsertCareer`;
    return axiosClient.post(url, career);
  },
  UpdateCareer: (career: Career): Promise<ListRespone<Career>> => {
    const url = `/career/UpdateCareer`;
    return axiosClient.put(url, career);
  },
  HideCareerByList: (listId: string[], check: boolean): Promise<ListRespone<Career>> => {
    const url = `/career/HideCareerByList?check=${check}`;
    return axiosClient.put(url, listId);
  },
  DeleteCareerByList: (listId: string[]): Promise<ListRespone<Career>> => {
    const url = `/career/DeleteCareerByList`;
    return axiosClient.delete(url, { data: listId });
  },
} as const;
