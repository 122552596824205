import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { GetListInforProductById } from "../../../../../app/InforProductSlice";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { PageTiltle } from "../../../components/pageTille";

function _ProductDetail() {
  const { t } = useTranslation(["serviceDetail"]);
  const params = useParams();
  const dispatch = useAppDispatch();
  const { loading, apis } = useAppSelector((state) => state.InforProducts);

  useEffect(() => {
    document.title = `${apis?.payload?.titleProduct || ""} `;
  }, [apis?.payload?.titleProduct, t]);
  useEffect(() => {
    if (params.id) {
      dispatch(GetListInforProductById({ id: params.id as string }));
    }
  }, [dispatch, params.id]);
  return (
    <div className="ProductDetail">
      <PageTiltle title={apis.payload?.titleProduct} subTitle={apis.payload?.titleProduct}
                  breadcrumb={t("serviceDetail:HomeLink")} breadcrumbLink="/" />

      <div className="site-main">
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 content-area order-lg-2">
                {loading ? (
                  <Skeleton count={40} />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: apis.payload?.contentProduct,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const ProductDetail = WithErrorBoundaryCustom(_ProductDetail);
