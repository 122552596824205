import { ListRespone } from "../../../models/commom";
import { Contact } from "../../../models/contact";
import { axiosClient } from "./axiosClient";

export const ContactApis = {
  GetListContactAdmin: (pageSize: number, pageNumber: number): Promise<ListRespone<Contact>> => {
    const url = `/Contact/GetListContactAdmin?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListContactClient: (pageSize: number, pageNumber: number): Promise<ListRespone<Contact>> => {
    const url = `/Contact/GetListContactClient?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListContactById: (IdContact: string): Promise<ListRespone<Contact>> => {
    const url = `/Contact/GetContactById?IdContact=${IdContact}`;
    return axiosClient.get(url);
  },
  DeleteContact: (IdContact: string): Promise<ListRespone<Contact>> => {
    const url = `/Contact/DeleteContact?IdContact=${IdContact}`;
    return axiosClient.delete(url);
  },
  InsertContact: (contact: Partial<Contact>): Promise<ListRespone<Contact>> => {
    const url = `/Contact/InsertContact`;
    return axiosClient.post(url, contact);
  },
  DeleteContactByList: (listId: string[]): Promise<ListRespone<Contact>> => {
    const url = `/Contact/DeleteContactByList`;
    return axiosClient.delete(url, { data: listId });
  },
} as const;
