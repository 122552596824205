import { globalVariable } from "../../../globalVariable";
import { ListRespone, ListResponeFormData } from "../../../models/commom";
import { LeaderShip } from "../../../models/leaderShip";
import { axiosClient, axiosClientFormData } from "./axiosClient";

export const LeaderShipApis = {
  GetListLeadershipAdmin: (pageSize: number, pageNumber: number): Promise<ListRespone<LeaderShip>> => {
    const url = `/Leadership/GetListLeadershipAdmin?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListLeadershipClient: (pageSize: number, pageNumber: number): Promise<ListRespone<LeaderShip>> => {
    const url = `/Leadership/GetListLeadershipClient?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListLeadershipById: (IdLeadership: string): Promise<ListRespone<LeaderShip>> => {
    const url = `/Leadership/GetListLeadershipById?IdLeadership=${IdLeadership}`;
    return axiosClient.get(url);
  },
  InsertLeadership: (leadership: Partial<LeaderShip>): Promise<ListResponeFormData> => {
    const url = `/Leadership/InsertLeadership`;
    return axiosClientFormData.post(url, leadership);
  },
  UpdateLeadership: (leadership: Partial<LeaderShip>): Promise<ListResponeFormData> => {
    const url = `/Leadership/UpdateLeadership`;
    return axiosClientFormData.put(url, leadership);
  },
  HideLeadership: (IdLeadership: string, check: boolean): Promise<ListRespone<LeaderShip>> => {
    const url = `/Leadership/HideLeadership?IdLeadership=${IdLeadership}&check=${check}`;
    return axiosClient.put(url);
  },
  DeleteLeadership: (IdLeadership: string): Promise<ListRespone<LeaderShip>> => {
    const url = `/Leadership/DeleteLeadership?IdLeadership=${IdLeadership}`;
    return axiosClient.delete(url);
  },
  GetFileLeadership: (fileName: string) => {
    return `${globalVariable.urlServerApi}/api/Leadership/GetFileLeadership?fileNameId=${fileName}`;
  },
} as const;
