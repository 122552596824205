import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoryProducts } from "../models/categoryProducts";
import { ListRespone } from "../models/commom";
import { CategoryProductsApis } from "../package/client/api/categoryProductApis";

interface CategoryProductsSliceState {
  loading: boolean;
  apis: ListRespone<CategoryProducts>;
  btnLoading: boolean;
  postLength: number;
}

const initialState: CategoryProductsSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as CategoryProducts,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListCategoryProductClient = createAsyncThunk(
  "CategoryProducts/GetListCategoryProductClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CategoryProductsApis.GetListCategoryProductionClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const CategoryProductsSlice = createSlice({
  name: "CategoryProducts",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListCategoryProductClient
    [GetListCategoryProductClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryProductClient.fulfilled.type]: (state, action: PayloadAction<ListRespone<CategoryProducts>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoryProductClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
  },
});

export default CategoryProductsSlice.reducer;
