import moment from "moment";
import { Fragment, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, NavLink } from "react-router-dom";
import { Autoplay, Navigation, Pagination, Parallax } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import { GetListCategoryServiceClient } from "../../../../../app/CategoryServicesSlice";
import { GetListContactAndIntroductionActived } from "../../../../../app/ContactAndIntroductionSlice";
import { GetListCorporatePartnerClient } from "../../../../../app/CorporatePartnerSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { GetListNewsClient } from "../../../../../app/NewsSlice";
import { useBreakPoint } from "../../../../../hooks/useBreakPoint";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { ContactAndIntroductionApis } from "../../../api/contactAndIntroductionApis";
import { CorporatePartnerApis } from "../../../api/corporatePartnerApis";
import { SlideApis } from "../../../api/slideApis";
import { GetListSlideClient } from "../HomePageSlice";
import { useTranslation } from "react-i18next";
import "./style.css";

export const GetImageInHTMlContent = (html: string) => {
  var doc = new DOMParser().parseFromString(html, "text/html");
  var img = doc.querySelector("img");
  return img?.src;
};

function _HomePage() {
  const { t } = useTranslation(["home"]);
  const breakpoint = useBreakPoint();
  const dispatch = useAppDispatch();
  const { Slideloading, ListSlide } = useAppSelector((state) => state.HomePage);
  const { loading, apis, apisType2, apisTYpe5 } = useAppSelector((state) => state.ContactAndIntroduction);
  const { loading: loadingNews, apis: apisNews } = useAppSelector((state) => state.News);
  const {
    loading: loadingCorporatePartner,
    apis: apisCorporatePartner,
  } = useAppSelector((state) => state.CorporatePartner);
  const {
    loading: loadingCategoryService,
    apis: apisCategoryServices,
  } = useAppSelector((state) => state.CategoryServices);

  useEffect(() => {
    document.title = t("home:Title");
  }, [t]);
  useEffect(() => {
    const fecthDatas = async () => {
      await Promise.all([
        dispatch(GetListSlideClient({ pageSize: 0, pageNumber: 0 })),
        dispatch(GetListContactAndIntroductionActived({ pageSize: 0, pageNumber: 0, type: 1 })),
        dispatch(GetListContactAndIntroductionActived({ pageSize: 0, pageNumber: 0, type: 2 })),
        dispatch(GetListContactAndIntroductionActived({ pageSize: 0, pageNumber: 0, type: 5 })),
        dispatch(GetListNewsClient({ pageSize: 6, pageNumber: 1 })),
        dispatch(GetListCorporatePartnerClient({ pageSize: 0, pageNumber: 0 })),
        dispatch(GetListCategoryServiceClient({ pageSize: 0, pageNumber: 3 })),
      ]);
    };
    fecthDatas();
  }, [dispatch]);

  return (
    <Fragment>
      <div className="homePage">
        {/* <div id='preloader'>
        <div id='status'>&nbsp;</div>
      </div> */}
        <div className="homePage__slider">
          <Swiper
            // style={{
            //   "--swiper-navigation-color": "#fff",
            //   "--swiper-pagination-color": "#fff",
            // }}
            speed={1000}
            parallax={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            navigation={false}
            modules={[Parallax, Pagination, Navigation, Autoplay]}
          >
            {ListSlide.map((item, index) => (
              <SwiperSlide key={item.id}>
                <div
                  slot="container-start"
                  className="parallax-bg"
                  style={{
                    backgroundImage: `url(${SlideApis.GetFileSlide(item.id + "." + item.fileNameExtention)})`,
                  }}
                >
                  <div className="title" data-swiper-parallax="-300">
                    {item.title}
                  </div>
                  <div className="text" data-swiper-parallax="-100">
                    <p>{item.description}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="site-main">
          <section className="ttm-row aboutus-section-style2 clearfix">
            <div className="container">
              <div className="row no-gutters align-items-center">
                <div className="col-lg-6">
                  <div className="ttm_single_image-wrapper">
                    {loading ? (
                      <Skeleton height={675} />
                    ) : (
                      <img
                        className="img-fluid"
                        src={
                          ContactAndIntroductionApis.GetFileContactAndIntroduction(
                            apisType2.listPayload[0]?.id + "." + apisType2.listPayload[0]?.col10,
                          ) || "images/single-img-two.png"
                        }
                        title="single-img-two"
                        alt="single-img-two"
                      />
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="spacing-4 ttm-bgcolor-grey res-991-mt-30">
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h5>{loading ? <Skeleton count={1} /> : apisType2.listPayload[0]?.col}</h5>
                        <h2 className="title">
                          {loading ? <Skeleton count={1} /> : apisType2.listPayload[0]?.col1}{" "}
                          <span>{loading ? <Skeleton count={1} /> : apisType2.listPayload[0]?.col2}</span>
                        </h2>
                      </div>
                      <div className="title-desc">
                        <p>{loading ? <Skeleton count={15} /> : apis.listPayload[0]?.col4} </p>
                      </div>
                    </div>

                    <div className="row no-gutters mt-20"></div>

                    <div className="separator">
                      <div className="sep-line mt-30 mb-15"></div>
                    </div>

                    <div className="margin_top20">
                      <div className="d-inline-block">
                        <NavLink
                          style={{ marginRight: 10 }}
                          className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-blue"
                          to="/about-us"
                        >
                          {t("home:SeeAlso")}
                        </NavLink>
                        <NavLink
                          className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-dark  margin_top15"
                          to="/contact-us"
                        >
                          {t("home:ContactUs")}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row"></div>
            </div>
          </section>

          <section className="ttm-row services-section ttm-bgcolor-darkgrey ttm-bg  clearfix">
            <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3">
                  <div className="section-title with-desc text-center clearfix">
                    <div className="title-header">
                      <h5> {loading ? <Skeleton count={1} /> : apisType2.listPayload[0]?.col3}</h5>
                      <h2 className="title">
                        {loading ? <Skeleton count={1} /> : apisType2.listPayload[0]?.col4}
                        {/* <br /> */}
                        <span>{loading ? <Skeleton count={1} /> : apisType2.listPayload[0]?.col5}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                {loadingCategoryService ? (
                  <Skeleton count={12} />
                ) : (
                  apisCategoryServices.listPayload.slice(0, 3).map((item, index) => (
                    <div className="col-lg-4 col-md-4" key={item.id}>
                      <div className="featured-icon-box style5 text-left mb-20">
                        {index === 0 ? (
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-color-white ttm-icon_element-size-lg">
                              <i className="ti ti-light-bulb"></i>
                            </div>
                          </div>
                        ) : index === 1 ? (
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-color-white ttm-icon_element-size-lg">
                              <i className="ti ti-bar-chart"></i>
                            </div>
                          </div>
                        ) : index === 2 ? (
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-color-white ttm-icon_element-size-lg">
                              <i className="ti ti-headphone-alt"></i>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>{loadingCategoryService ? <Skeleton count={1} /> : item.nameService}</h5>
                          </div>
                          <div className="featured-desc">
                            <p className="featured-desc-text">{loadingCategoryService ?
                              <Skeleton count={5} /> : item.description}.</p>
                          </div>
                          <Link
                            className="ttm-btn ttm-btn-size-sm ttm-btn-color-white btn-inline ttm-icon-btn-right mt-15"
                            to={`/services-category-${item.id}`}
                          >
                            {t("home:ReadMore")}
                            <i className="ti ti-angle-double-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </section>

          <section className="ttm-row zero-padding-section mt_95 res-991-mt-0 clearfix">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-lg-3">
                  <div className="col-bg-img-three ttm-bg ttm-col-bgimage-yes res-991-h-auto">
                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                      <div className="ttm-bg-layer-inner"></div>
                    </div>
                  </div>

                  <img src="images/bg-image/col-bgimage-3.jpg" className="ttm-equal-height-image" alt="col-bgimage-3" />
                </div>
                <div className="col-lg-9">
                  <div className="ttm-bgcolor-skincolor ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                    <div
                      className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                      style={{
                        backgroundImage: `url("${
                          ContactAndIntroductionApis.GetFileContactAndIntroduction(
                            apisTYpe5.listPayload[0]?.id + "." + apisTYpe5.listPayload[0]?.col10,
                          ) || "images/bg-image/col-bgimage-3.jpg"
                        }") `,
                      }}
                    >
                      <div className="ttm-bg-layer-inner"></div>
                    </div>
                    <div className="layer-content">
                      <div className="spacing-6 ttm-textcolor-white">
                        <h3 className="mb-5">{apisTYpe5.listPayload[0]?.col}</h3>
                        <p className="mb-0">{apisTYpe5.listPayload[0]?.col1}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="ttm-row blog-section clearfix">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-title text-center with-desc clearfix">
                    <div className="title-header">
                      <h5> {t("home:NewsUs")}</h5>
                      <h2 className="title">
                        {t("home:SeeNews")} <span> {t("home:latest")}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                {loadingNews ? (
                    <div style={{ width: "100%" }}>
                      <Skeleton count={18} />
                    </div>
                  ) :
                  <Swiper
                    className="blog-slide owl-carousel owl-theme owl-loaded "
                    slidesPerView={breakpoint.isDesktop ? 3 : breakpoint.isTablet ? 2 : 1}
                    spaceBetween={30}
                    centeredSlides={true}
                    loop={true}
                    speed={1500}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                  >
                    {apisNews.listPayload.map((item, index) => (
                      <SwiperSlide key={item.id}>
                        <div className="featured-imagebox featured-imagebox-blog" key={item.id}>
                          <div className="featured-thumbnail">
                            <img
                              className="img-fluid"
                              src={GetImageInHTMlContent(item.contentNews) || "images/logoBigSize.jpg"}
                              alt="image"
                              style={{ height: "227.55px" }}
                            />
                            <div className="ttm-blog-overlay-iconbox">
                              <Link to={`/blog-detail-${item.id}&null`}>
                                <i className="ti ti-plus"></i>
                              </Link>
                            </div>
                            <div className="ttm-box-view-overlay"></div>
                          </div>
                          <div className="featured-content">
                            <div className="ttm-box-post-date">
                              <span className="ttm-entry-date">
                                <time className="entry-date" dateTime="2019-01-16T07:07:55+00:00">
                                  {loadingNews ? <Skeleton count={1} /> : moment(item.createdDate).format("DD")}
                                  <span className="entry-month entry-year">
                                    {loadingNews ? <Skeleton count={1} /> : moment(item.createdDate).format("MMM")}
                                  </span>
                                </time>
                              </span>
                            </div>
                            <div
                              className="featured-title"
                              style={{
                                height: 50,
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              <h5>
                                <Link to={`/blog-detail-${item.id}&null`}>
                                  {loadingNews ? <Skeleton count={1} /> : item.titleNews}{" "}
                                </Link>
                              </h5>
                            </div>
                            <div className="post-meta">
                              <span className="ttm-meta-line">
                                <i className="fa fa-comments"></i>{t("home:Comments")}
                              </span>
                              <span className="ttm-meta-line">
                                <i className="fa fa-user"></i>
                                {loadingNews ? <Skeleton count={1} /> : item.postBy}
                              </span>
                            </div>
                            <div
                              className="featured-desc"
                              style={{
                                height: 75,
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                              }}
                              data-tooltip={item?.description}
                            >
                              <p>{loadingNews ? <Skeleton count={3} /> : item.description}</p>
                            </div>
                            <Link
                              className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                              to={`/blog-detail-${item.id}&null`}
                            >
                              {t("home:ReadMore")} <i className="ti ti-angle-double-right"></i>
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                }
              </div>
            </div>
          </section>

          <div
            className="ttm-row our-client-section grey-bg ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes mt_60 clearfix">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {loadingCorporatePartner ? (
                    <Skeleton count={10} />
                  ) : (
                    <Swiper
                      slidesPerView={breakpoint.isDesktop ? 5 : breakpoint.isTablet ? 3 : 2}
                      centeredSlides={true}
                      speed={2000}
                      spaceBetween={30}
                      loop={true}
                      autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay]}
                    >
                      {apisCorporatePartner.listPayload.map((item, index) => (
                        <SwiperSlide key={item.id}>
                          <div className="client-box ttm-box-view-boxed-logo" key={item.id}>
                            <div className="client">
                              <div className="ttm-client-logo-tooltip" data-tooltip={item.title}>
                                <img
                                  className="img-fluid"
                                  src={CorporatePartnerApis.GetFileCorporatePartner(item.id + "." + item.fileNameExtention)}
                                  alt="image"
                                  style={{ height: "74px", width: "200px", objectFit: "cover" }}
                                />
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export const HomePage = WithErrorBoundaryCustom(_HomePage);
