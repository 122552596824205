import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ListRespone } from "../models/commom";
import { MenuApis } from "../package/client/api/MenuApis";
import { MenuDto } from "../models/MenuDto";

interface MenuState {
  loading: boolean;
  apis: ListRespone<MenuDto>;
  btnLoading: boolean;
  postLength: number;
}

const initialState: MenuState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as MenuDto,
    totalElament: 0,
  },
  btnLoading: false,
  postLength: 0,
};

export const GetListMenuClient = createAsyncThunk(
  "Menu/GetListMenuClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await MenuApis.GetListMenuClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const MenuSlice = createSlice({
  name: "Menu",
  initialState,
  reducers: {},
  extraReducers: {
    [GetListMenuClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListMenuClient.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListMenuClient.rejected.type]: (state) => {
      state.loading = false;
    },
  },
});
export default MenuSlice.reducer;