import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoryServices } from "../models/categoryServices";
import { ListRespone } from "../models/commom";
import { CategoryServicesApis } from "../package/client/api/categoryServicesApis";

interface CategoryServicesSliceState {
  loading: boolean;
  apis: ListRespone<CategoryServices>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: CategoryServicesSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as CategoryServices,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListCategoryServiceClient = createAsyncThunk(
  "CategoryServices/GetListCategoryServiceClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CategoryServicesApis.GetListCategoryServiceClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const CategoryServicesSlice = createSlice({
  name: "CategoryServices",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListCategoryServiceClient
    [GetListCategoryServiceClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryServiceClient.fulfilled.type]: (state, action: PayloadAction<ListRespone<CategoryServices>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoryServiceClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
  },
});

export default CategoryServicesSlice.reducer;
