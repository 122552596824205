// create store redux toolkit
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import rootSaga from "./rootSaga";
import HomePageSlice from "../package/client/features/homePage/HomePageSlice";
import ContactAndIntroductionSlice from "./ContactAndIntroductionSlice";
import CategoryNewSlice from "./CategoryNewSlice";
import NewsSlice from "./NewsSlice";
import CorporatePartnerSlice from "./CorporatePartnerSlice";
import LeaderShipSlice from "./LeaderShipSlice";
import CategoryServicesSlice from "./CategoryServicesSlice";
import CareerSlice from "./CareerSlice";
import ContactSlice from "./ContactSlice";
import InforServicesSlice from "./InforServicesSlice";
import CategoryAboutUsSlice from "./CategoryAboutUsSlice";
import AboutUsSlice from "./AboutUsSlice";
import CategoryProductsSlice from "./CategoryProductsSlice";
import InforProductSlice from "./InforProductSlice";
import MenuSlice from "./MenuSlice";


const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();
const arrMiddleware: any[] = [sagaMiddleware];
if (process.env.NODE_ENV === "development") {
  arrMiddleware.push(logger);
}

export const store = configureStore({
  reducer: {
    HomePage: HomePageSlice,
    ContactAndIntroduction: ContactAndIntroductionSlice,
    CategoryNew: CategoryNewSlice,
    CategoryServices: CategoryServicesSlice,
    News: NewsSlice,
    CorporatePartner: CorporatePartnerSlice,
    LeaderShip: LeaderShipSlice,
    Career: CareerSlice,
    Contact: ContactSlice,
    InforServices: InforServicesSlice,
    CategoryAboutUs: CategoryAboutUsSlice,
    AboutUs: AboutUsSlice,
    CategoryProducts: CategoryProductsSlice,
    InforProducts: InforProductSlice,
    MenuSlice: MenuSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...arrMiddleware),
});
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
