import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListRespone } from "../models/commom";
import { ContactAndIntroduction } from "../models/contactAndIntroduction";
import { ContactAndIntroductionApis } from "../package/client/api/contactAndIntroductionApis";

interface ContactAndIntroductionSliceState {
  loading: boolean;
  apis: ListRespone<ContactAndIntroduction>;
  apisType2: ListRespone<ContactAndIntroduction>;
  apisType3: ListRespone<ContactAndIntroduction>;
  apisTYpe4: ListRespone<ContactAndIntroduction>;
  apisTYpe5: ListRespone<ContactAndIntroduction>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: ContactAndIntroductionSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as ContactAndIntroduction,
  },
  apisType2: {
    listPayload: [],
    payload: {} as ContactAndIntroduction,
  },
  apisType3: {
    listPayload: [],
    payload: {} as ContactAndIntroduction,
  },
  apisTYpe4: {
    listPayload: [],
    payload: {} as ContactAndIntroduction,
  },
  apisTYpe5: {
    listPayload: [],
    payload: {} as ContactAndIntroduction,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListContactAndIntroductionActived = createAsyncThunk(
  "slideManage/GetListContactAndIntroductionActived",
  async (params: { pageSize: number; pageNumber: number; type: number }, thunkAPI) => {
    try {
      const response = await ContactAndIntroductionApis.GetListContactAndIntroductionActived(
        params.pageSize,
        params.pageNumber,
        params.type
      );
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const ContactAndIntroductionSlice = createSlice({
  name: "ContactAndIntroduction",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListContactAndIntroductionActived
    [GetListContactAndIntroductionActived.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListContactAndIntroductionActived.fulfilled.type]: (state, action) => {
      state.loading = false;
      if (action.meta.arg.type === 4) state.apisTYpe4 = action.payload;
      else if (action.meta.arg.type === 5) state.apisTYpe5 = action.payload;
      else if (action.meta.arg.type === 3) state.apisType3 = action.payload;
      else if (action.meta.arg.type === 2) state.apisType2 = action.payload;
      else if (action.meta.arg.type === 1) state.apis = action.payload;
    },
    [GetListContactAndIntroductionActived.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
  },
});

export default ContactAndIntroductionSlice.reducer;
