import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListRespone } from "../models/commom";
import { News } from "../models/news";
import { NewsApis } from "../package/client/api/newsApis";

interface NewSliceState {
  loading: boolean;
  apis: ListRespone<News>;
  apisID: ListRespone<News>;
  apisByCategory: ListRespone<News>;
  btnLoading: boolean;
  postLength: number;
}

const initialState: NewSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as News,
    totalElament: 0,
  },
  apisID: {
    listPayload: [],
    payload: {} as News,
  },
  apisByCategory: {
    listPayload: [],
    payload: {} as News,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListNewsClient = createAsyncThunk(
  "News/GetListNewsClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await NewsApis.GetListNewsClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetNewsByIdCategoriesNew = createAsyncThunk(
  "News/GetNewsByIdCategoriesNew",
  async (params: { IdCategoriesNew: string }, thunkAPI) => {
    try {
      const response = await NewsApis.GetNewsByIdCategoriesNew(params.IdCategoriesNew);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetListNewsById = createAsyncThunk("News/GetListNewsById", async (params: { IdNews: string }, thunkAPI) => {
  try {
    const response = await NewsApis.GetListNewsById(params.IdNews);
    if (response.success) {
      return thunkAPI.fulfillWithValue(response);
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const GetListNewsByIdCategoriesNew = createAsyncThunk(
  "News/GetListNewsByIdCategoriesNew",
  async (params: { pageSize: number; pageNumber: number; IdCategoriesNew: string }, thunkAPI) => {
    try {
      const response = await NewsApis.GetListNewsByIdCategoriesNew(params.pageSize, params.pageNumber, params.IdCategoriesNew);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const NewSlice = createSlice({
  name: "News",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListNewsClient
    [GetListNewsClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListNewsClient.fulfilled.type]: (state, action: PayloadAction<ListRespone<News>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListNewsClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetNewsByIdCategoriesNew
    [GetNewsByIdCategoriesNew.pending.type]: (state) => {
      state.loading = true;
    },
    [GetNewsByIdCategoriesNew.fulfilled.type]: (state, action: PayloadAction<ListRespone<News>>) => {
      state.loading = false;
      state.apisID = action.payload;
    },
    [GetNewsByIdCategoriesNew.rejected.type]: (state) => {
      state.loading = false;
      state.apisID.listPayload = [];
    },
    //#endregion
    //#region GetListNewsById
    [GetListNewsById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListNewsById.fulfilled.type]: (state, action: PayloadAction<ListRespone<News>>) => {
      state.loading = false;
      state.apisID = action.payload;
    },
    [GetListNewsById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListNewsByIdCategoriesNew
    [GetListNewsByIdCategoriesNew.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListNewsByIdCategoriesNew.fulfilled.type]: (state, action: PayloadAction<ListRespone<News>>) => {
      state.loading = false;
      state.apisByCategory = action.payload;
    },
    [GetListNewsByIdCategoriesNew.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion

  },
});

export default NewSlice.reducer;
