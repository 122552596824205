import { Fragment, useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { GetListCategoryServiceClient } from "../../../../app/CategoryServicesSlice";
import { GetListContactAndIntroductionActived } from "../../../../app/ContactAndIntroductionSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import WithErrorBoundaryCustom from "../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { ContactAndIntroductionApis } from "../../api/contactAndIntroductionApis";
import Skeleton from "react-loading-skeleton";
import { GetListCategoryAboutUsAppBar } from "../../../../app/CategoryAboutUsSlice";
import { GetListCategoriesNewsClient } from "../../../../app/CategoryNewSlice";
import { useTranslation } from "react-i18next";
import englishFlag from "../../asset/countryFlags/englist.png";
import vietNamFlag from "../../asset/countryFlags/vietnam.png";
import { GetListCategoryProductClient } from "../../../../app/CategoryProductsSlice";
import { GetListMenuClient } from "../../../../app/MenuSlice";

function _Header() {
  const { loading, apis } = useAppSelector((state) => state.ContactAndIntroduction);
  const {
    loading: loadingCategoryServices,
    apis: apisCategoryServices,
  } = useAppSelector((state) => state.CategoryServices);
  const {
    loading: loadingCategoryProducts,
    apis: apisCategoryProducts,
  } = useAppSelector((state) => state.CategoryProducts);
  const { loading: loadingCategoryNew, apis: apisCategoryNew } = useAppSelector((state) => state.CategoryNew);
  const {
    loading: loadingCategoryAboutUsAppBar,
    apisWithAppBar: apisCategoryAboutUsAppBar,
  } = useAppSelector((state) => state.CategoryAboutUs);
  const {
    loading: loadingMenu,
    apis: apisMenu,
  } = useAppSelector((state) => state.MenuSlice);

  const dispatch = useAppDispatch();
  const { t } = useTranslation(["header"]);
  const location = useLocation();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [menuDropdownVisible, setMenuDropdownVisible] = useState(false);
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  const [isOpenSubMenuAboutUs, setIsOpenSubMenuAboutUs] = useState(false);
  const [isOpenSubMenuNews, setIsOpenSubMenuNews] = useState(false);
  const [isOpenSubMenuProduct, setIsOpenSubMenuProduct] = useState(false);
  const [isOpenLanguage, setIsOpenLanguage] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fecthDatas = async () => {
      await Promise.all([
        dispatch(GetListContactAndIntroductionActived({ pageNumber: 0, pageSize: 0, type: 1 })),
        dispatch(GetListCategoryServiceClient({ pageNumber: 0, pageSize: 0 })),
        dispatch(GetListCategoryAboutUsAppBar({ pageNumber: 0, pageSize: 0 })),
        dispatch(GetListCategoriesNewsClient({ pageNumber: 0, pageSize: 0 })),
        dispatch(GetListCategoryProductClient({ pageNumber: 0, pageSize: 0 })),
        dispatch(GetListMenuClient({ pageNumber: 0, pageSize: 0 })),
      ]);
    };
    fecthDatas();
  }, [dispatch]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 50) {
        headerRef.current?.classList.add("fixed-header");
        headerRef.current?.classList.add("visible-title");
      } else {
        headerRef.current?.classList.remove("fixed-header");
        headerRef.current?.classList.remove("visible-title");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="Header">
      {/* <div id='preloader'>
        <div id='status'>&nbsp;</div>
      </div> */}
      <header id="masthead" className="header ttm-header-style-01">
        <div className="ttm-topbar-wrapper clearfix">
          <div className="container">
            <div className="ttm-topbar-content">
              <ul className="top-contact text-left">
                <li>
                  <i className="fa fa-map-marker"></i>
                  {loading ? <Fragment>loading ...</Fragment> : apis.listPayload[0]?.col}
                </li>
                <li>
                  <i className="fa fa-envelope-o"></i>
                  <a href={`mailto:${apis.listPayload[0]?.col1}`}>
                    {loading ? <Fragment>loading ...</Fragment> : apis.listPayload[0]?.col1}
                  </a>
                </li>
              </ul>
              <div className="topbar-right text-right">
                <ul className="top-contact">
                  <li>
                    <i className="fa fa-clock-o"></i>{t("header:WorkingHours")}:{" "}
                    {loading ? <Fragment>loading ...</Fragment> : apis.listPayload[0]?.col2}
                  </li>
                </ul>
                <div className="ttm-social-links-wrapper list-inline">
                  <ul className="social-icons">
                    <li>
                      <a
                        href="https://www.facebook.com/BTHINVEST/"
                        target={"_blank"}
                        className=" tooltip-bottom"
                        data-tooltip="Facebook"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    {/* <li>
                      <Link to="#" className=" tooltip-bottom" data-tooltip="Twitter">
                        <i className="fa fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className=" tooltip-bottom" data-tooltip="Linkedin">
                        <i className="fa fa-linkedin"></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="header-btn">
                  <Link className="ttm-btn ttm-btn-size-md  ttm-btn-bgcolor-skincolor" to="/contact-us">
                    {t("header:GetAQuote")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ttm-header-wrap">
          <div id="ttm-stickable-header-w" className="ttm-stickable-header-w clearfix">
            <div id="site-header-menu" className="site-header-menu">
              <div className="site-header-menu-inner ttm-stickable-header" ref={headerRef}>
                <div className="container">
                  <div className="site-branding">
                    <Link className="home-link" to="/" title="Trang chủ" rel="home">
                      {loading ? (
                        <Fragment>loading...</Fragment>
                      ) : (
                        <img
                          id="logo-img"
                          className="img-center lazyload"
                          data-src={ContactAndIntroductionApis.GetFileContactAndIntroduction(
                            apis.listPayload[0]?.id + "." + apis.listPayload[0]?.col10,
                          )}
                          src={ContactAndIntroductionApis.GetFileContactAndIntroduction(
                            apis.listPayload[0]?.id + "." + apis.listPayload[0]?.col10,
                          )}
                          alt="logo"
                        />
                      )}
                    </Link>
                  </div>

                  <div id="site-navigation" className="site-navigation">
                    <div className="ttm-rt-contact">
                      <div className="ttm-header-icons" style={{ zIndex: 99999 }}>
                        <div className="dropdown">
                          <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsOpenLanguage(!isOpenLanguage);
                            }}
                          >
                            <i className="fa fa-globe"></i>
                          </button>
                          <div className="dropdown-menu" style={{ display: isOpenLanguage ? "block" : "none" }}>
                            <button
                              disabled={window.localStorage.getItem("language") === "en"}
                              style={{
                                cursor: "pointer",
                                backgroundColor: window.localStorage.getItem("language") === "en" ? "#e9ecef" : "white",
                              }}
                              className="dropdown-item"
                              onClick={() => {
                                window.localStorage.setItem("language", "en");
                                window.location.reload();
                              }}
                            >
                              <img alt="flag" src={englishFlag}
                                   style={{ width: 20, height: 20, marginRight: 20 }} /> English
                            </button>
                            <button
                              disabled={window.localStorage.getItem("language") === "vi"}
                              style={{
                                cursor: "pointer",
                                backgroundColor: window.localStorage.getItem("language") === "vi" ? "#e9ecef" : "white",
                              }}
                              className="dropdown-item"
                              onClick={() => {
                                window.localStorage.setItem("language", "vi");
                                window.location.reload();
                              }}
                            >
                              <img alt="flag" src={vietNamFlag} style={{ width: 20, height: 20, marginRight: 20 }} />
                              Tiếng Việt
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ttm-rt-contact">
                      <div className="ttm-header-icons">
                        <div className="ttm-header-icon ttm-header-search-link">
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsSearchOpen(!isSearchOpen);
                            }}
                            className={`${isSearchOpen ? "open" : ""}`}
                          >
                            <i className={`ti ${isSearchOpen ? "ti-close" : "ti-search"}`}></i>
                          </a>

                          <div className={`ttm-search-overlay ${isSearchOpen ? "st-show" : ""}`}>
                            <form method="get" className="ttm-site-searchform" action="#">
                              <div className="w-search-form-h">
                                <div className="w-search-form-row">
                                  <div className="w-search-input">
                                    <input
                                      type="search"
                                      className="field searchform-s"
                                      name="s"
                                      placeholder={t("header:placeholderSearch")}
                                    />
                                    <button type="submit">
                                      <i className="ti ti-search"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="ttm-menu-toggle"
                      onClick={(e) => {
                        e.preventDefault();
                        setMenuDropdownVisible(!menuDropdownVisible);
                      }}
                    >
                      <input type="checkbox" id="menu-toggle-form" />
                      <label htmlFor="menu-toggle-form" className="ttm-menu-toggle-block">
                        <span className="toggle-block toggle-blocks-1"></span>
                        <span className="toggle-block toggle-blocks-2"></span>
                        <span className="toggle-block toggle-blocks-3"></span>
                      </label>
                    </div>
                    <nav id="menu" className={`menu ${menuDropdownVisible ? "active" : ""}`}>
                      <ul className="dropdown">
                        {apisMenu?.listPayload?.map((item, index) => (
                          item.menuCode === "/" || item?.menuCode === "/Career" || item?.menuCode === "/Contact-us" ?
                            <li key={item.id} className={location.pathname.includes(item.menuCode) ? "active" : ""}>
                              <Link to={item.menuCode} onClick={() => setMenuDropdownVisible(false)}>
                                {item?.nameMenu}
                              </Link>
                            </li>
                            : <li
                              key={item.id}
                              className={
                                location.pathname.includes(item.menuCode) ? "has-submenu active" : "has-submenu"
                              }
                            >
                              {item.menuCode === "/about-us" &&
                                <>
                                  <NavLink
                                    to={item.menuCode}
                                    className={"moblie"}
                                    onClick={() => setIsOpenSubMenuAboutUs(!isOpenSubMenuAboutUs)}
                                  >
                                    {item.nameMenu}
                                  </NavLink>
                                  <ul className={`sub-menu ${isOpenSubMenuAboutUs ? "active" : ""}`}>
                                    {apisCategoryAboutUsAppBar.listPayload.map((item, index) => (
                                      <li key={item.id}>
                                        <Link onClick={() => setMenuDropdownVisible(false)}
                                              to={`/about-us-detail-${item.id}`}>
                                          {item.nameAboutUs || <Skeleton count={1} />}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              }
                              {item.menuCode === "/services" &&
                                <>
                                  <NavLink
                                    to={item.menuCode}
                                    className={"moblie"}
                                    onClick={() => setIsOpenSubMenu(!isOpenSubMenu)}
                                  >
                                    {item.nameMenu}
                                  </NavLink>
                                  <ul className={`sub-menu ${isOpenSubMenu ? "active" : ""}`}>
                                    {apisCategoryServices.listPayload.map((item, index) => (
                                      <li key={item.id}>
                                        <Link onClick={() => setMenuDropdownVisible(false)}
                                              to={`/services-category-${item.id}`}>
                                          {item.nameService || <Skeleton count={1} />}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              }
                              {item.menuCode === "/product" &&
                                <>
                                  <NavLink
                                    to={item.menuCode}
                                    className={"moblie"}
                                    onClick={() => setIsOpenSubMenuProduct(!isOpenSubMenuProduct)}
                                  >
                                    {item.nameMenu}
                                  </NavLink>
                                  <ul className={`sub-menu ${isOpenSubMenuProduct ? "active" : ""}`}>
                                    {apisCategoryProducts.listPayload.map((item, index) => (
                                      <li key={item.id}>
                                        <Link onClick={() => setMenuDropdownVisible(false)}
                                              to={`/product-category-${item.id}`}>
                                          {item.nameProduct || <Skeleton count={1} />}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              }
                              {item.menuCode === "/blog" &&
                                <>
                                  <NavLink
                                    to={item.menuCode}
                                    className={"moblie"}
                                    onClick={() => setIsOpenSubMenuNews(!isOpenSubMenuNews)}
                                  >
                                    {item.nameMenu}
                                  </NavLink>
                                  <ul className={`sub-menu ${isOpenSubMenuNews ? "active" : ""}`}>
                                    {apisCategoryNew.listPayload.map((item, index) => (
                                      <li key={item.id}>
                                        <Link onClick={() => setMenuDropdownVisible(false)}
                                              to={`/blog-category-${item.id}`}>
                                          {item.nameCategorie || <Skeleton count={1} />}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              }
                            </li>
                        ))}

                        {/*<li className={location.pathname.length === 1 ? "active" : ""}>*/}
                        {/*  <Link to="/" onClick={() => setMenuDropdownVisible(false)}>*/}
                        {/*    {t("header:home")}*/}
                        {/*  </Link>*/}
                        {/*  /!* <NavLink to='/'>About us</NavLink> *!/*/}
                        {/*</li>*/}
                        {/*<li className={location.pathname.includes("/about-us") ? "has-submenu active" : "has-submenu"}>*/}
                        {/*  <NavLink to="/about-us" className={"moblie"}*/}
                        {/*           onClick={() => setIsOpenSubMenuAboutUs(!isOpenSubMenuAboutUs)}>*/}
                        {/*    {t("header:about")}*/}
                        {/*  </NavLink>*/}
                        {/*  <ul className={`sub-menu ${isOpenSubMenuAboutUs ? "active" : ""}`}>*/}
                        {/*    {apisCategoryAboutUsAppBar.listPayload.map((item, index) => (*/}
                        {/*      <li key={item.id}>*/}
                        {/*        <Link onClick={() => setMenuDropdownVisible(false)} to={`/about-us-detail-${item.id}`}>*/}
                        {/*          {item.nameAboutUs || <Skeleton count={1} />}*/}
                        {/*        </Link>*/}
                        {/*      </li>*/}
                        {/*    ))}*/}
                        {/*  </ul>*/}
                        {/*</li>*/}
                        {/*<li className={location.pathname.includes("/services") ? "has-submenu active" : "has-submenu"}>*/}
                        {/*  <NavLink to="services" className={"moblie"} onClick={() => setIsOpenSubMenu(!isOpenSubMenu)}>*/}
                        {/*    {t("header:solution")}*/}
                        {/*  </NavLink>*/}
                        {/*  <ul className={`sub-menu ${isOpenSubMenu ? "active" : ""}`}>*/}
                        {/*    {apisCategoryServices.listPayload.map((item, index) => (*/}
                        {/*      <li key={item.id}>*/}
                        {/*        <Link onClick={() => setMenuDropdownVisible(false)}*/}
                        {/*              to={`/services-category-${item.id}`}>*/}
                        {/*          {item.nameService || <Skeleton count={1} />}*/}
                        {/*        </Link>*/}
                        {/*      </li>*/}
                        {/*    ))}*/}
                        {/*  </ul>*/}
                        {/*</li>*/}
                        {/*<li className={location.pathname.includes("/product") ? "has-submenu active" : "has-submenu"}>*/}
                        {/*  <NavLink to="product" className={"moblie"}*/}
                        {/*           onClick={() => setIsOpenSubMenuProduct(!isOpenSubMenuProduct)}>*/}
                        {/*    {t("header:Product")}*/}
                        {/*  </NavLink>*/}
                        {/*  <ul className={`sub-menu ${isOpenSubMenuProduct ? "active" : ""}`}>*/}
                        {/*    {apisCategoryProducts.listPayload.map((item, index) => (*/}
                        {/*      <li key={item.id}>*/}
                        {/*        <Link onClick={() => setMenuDropdownVisible(false)}*/}
                        {/*              to={`/product-category-${item.id}`}>*/}
                        {/*          {item.nameProduct || <Skeleton count={1} />}*/}
                        {/*        </Link>*/}
                        {/*      </li>*/}
                        {/*    ))}*/}
                        {/*  </ul>*/}
                        {/*</li>*/}

                        {/*<li className={location.pathname.includes("/blog") ? "has-submenu active" : "has-submenu"}>*/}
                        {/*  <NavLink to="blog" className={"moblie"}*/}
                        {/*           onClick={() => setIsOpenSubMenuNews(!isOpenSubMenuNews)}>*/}
                        {/*    {t("header:news")}*/}
                        {/*  </NavLink>*/}
                        {/*  <ul className={`sub-menu ${isOpenSubMenuNews ? "active" : ""}`}>*/}
                        {/*    {apisCategoryNew.listPayload.map((item, index) => (*/}
                        {/*      <li key={item.id}>*/}
                        {/*        <Link onClick={() => setMenuDropdownVisible(false)} to={`/blog-category-${item.id}`}>*/}
                        {/*          {item.nameCategorie || <Skeleton count={1} />}*/}
                        {/*        </Link>*/}
                        {/*      </li>*/}
                        {/*    ))}*/}
                        {/*  </ul>*/}
                        {/*</li>*/}
                        {/*<li className={location.pathname.includes("/career") ? "active" : ""}>*/}
                        {/*  <Link to="/career" onClick={() => setMenuDropdownVisible(false)}>*/}
                        {/*    {t("header:career")}*/}
                        {/*  </Link>*/}
                        {/*</li>*/}
                        {/*<li className={location.pathname.includes("/contact-us") ? "active" : ""}>*/}
                        {/*  <Link onClick={() => setMenuDropdownVisible(false)} to="/contact-us">*/}
                        {/*    {t("header:contact")}*/}
                        {/*  </Link>*/}
                        {/*</li>*/}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export const Header = WithErrorBoundaryCustom(_Header);
