import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoriesNew } from "../models/categoriesNew";
import { ListRespone } from "../models/commom";
import { CategoriesNewApis } from "../package/client/api/categoriesNewApis";

interface CategoryNewSliceState {
  loading: boolean;
  apis: ListRespone<CategoriesNew>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: CategoryNewSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as CategoriesNew,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListCategoriesNewsClient = createAsyncThunk(
  "CategoryNew/GetListCategoriesNewsClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CategoriesNewApis.GetListCategoriesNewsClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListCategoriesNewsById = createAsyncThunk(
  "CategoryNew/GetListCategoriesNewsById",
  async (params: { id: string }, thunkAPI) => {
    try {
      const response = await CategoriesNewApis.GetListCategoriesNewsById(params.id);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const CategoryNewSlice = createSlice({
  name: "CategoryNew",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListCategoriesNewsClient
    [GetListCategoriesNewsClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoriesNewsClient.fulfilled.type]: (state, action: PayloadAction<ListRespone<CategoriesNew>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoriesNewsClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListCategoriesNewsById
    [GetListCategoriesNewsById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoriesNewsById.fulfilled.type]: (state, action: PayloadAction<ListRespone<CategoriesNew>>) => {
      state.loading = false;
      state.apis.payload = action.payload.payload;
    },
    [GetListCategoriesNewsById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
  },
});

export default CategoryNewSlice.reducer;
