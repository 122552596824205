import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListRespone } from "../../../../models/commom";
import { Slide } from "../../../../models/slide";

import { SlideApis } from "../../api/slideApis";
interface HomePageSliceState {
  Slideloading: boolean;
  ListSlide: Slide[];
  btnLoading: boolean;
  postLength: number;
}
const initialState: HomePageSliceState = {
  Slideloading: false,
  ListSlide: [],
  btnLoading: false,
  postLength: 0,
};
export const GetListSlideClient = createAsyncThunk(
  "slideManage/GetListSlideClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await SlideApis.GetListSlideClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const HomePageSlice = createSlice({
  name: "SliceManageSlice",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListSlideClient
    [GetListSlideClient.pending.type]: (state) => {
      state.Slideloading = true;
    },
    [GetListSlideClient.fulfilled.type]: (state, action: PayloadAction<ListRespone<Slide>>) => {
      state.Slideloading = false;
      state.ListSlide = action.payload.listPayload;
    },
    [GetListSlideClient.rejected.type]: (state) => {
      state.Slideloading = false;
    },
    //#endregion
  },
});

export default HomePageSlice.reducer;
