import { globalVariable } from "../../../globalVariable";
import { ListRespone, ListResponeFormData } from "../../../models/commom";
import { ContactAndIntroduction } from "../../../models/contactAndIntroduction";
import { axiosClient, axiosClientFormData } from "./axiosClient";

export const ContactAndIntroductionApis = {
  GetListContactAndIntroductionAdmin: (
    pageSize: number,
    pageNumber: number,
    Type?: number
  ): Promise<ListRespone<ContactAndIntroduction>> => {
    const url = `/ContactAndIntroduction/GetListContactAndIntroductionAdmin?pageSize=${pageSize}&pageNumber=${pageNumber}&Type=${Type}`;
    return axiosClient.get(url);
  },
  GetListContactAndIntroductionActived: (
    pageSize: number,
    pageNumber: number,
    Type: number
  ): Promise<ListRespone<ContactAndIntroduction>> => {
    const url = `/ContactAndIntroduction/GetListContactAndIntroductionActived?pageSize=${pageSize}&pageNumber=${pageNumber}&Type=${Type}`;
    return axiosClient.get(url);
  },
  GetListContactAndIntroductionById: (
    IdContactAndIntroduction: string
  ): Promise<ListRespone<ContactAndIntroduction>> => {
    const url = `/ContactAndIntroduction/GetContactAndIntroductionById?IdContactAndIntroduction=${IdContactAndIntroduction}`;
    return axiosClient.get(url);
  },
  InsertContactAndIntroduction: (
    contactAndIntroduction: Partial<ContactAndIntroduction>
  ): Promise<ListResponeFormData> => {
    const url = `/ContactAndIntroduction/InsertContactAndIntroduction`;
    return axiosClientFormData.post(url, contactAndIntroduction);
  },
  UpdateContactAndIntroduction: (
    contactAndIntroduction: Partial<ContactAndIntroduction>
  ): Promise<ListResponeFormData> => {
    const url = `/ContactAndIntroduction/UpdateContactAndIntroduction`;
    return axiosClientFormData.put(url, contactAndIntroduction);
  },
  ActiveContactAndIntroduction: (
    IdContactAndIntroduction: string,
    isActive: boolean
  ): Promise<ListRespone<ContactAndIntroduction>> => {
    const url = `/ContactAndIntroduction/ActiveContactAndIntroduction?IdContactAndIntroduction=${IdContactAndIntroduction}&isActive=${isActive}`;
    return axiosClient.put(url);
  },
  DeleteContactAndIntroduction: (
    IdContactAndIntroduction: string
  ): Promise<ListRespone<ContactAndIntroduction>> => {
    const url = `/ContactAndIntroduction/DeleteContactAndIntroduction?IdContactAndIntroduction=${IdContactAndIntroduction}`;
    return axiosClient.delete(url);
  },
  GetFileContactAndIntroduction: (fileName: string) => {
    return `${globalVariable.urlServerApi}/api/ContactAndIntroduction/GetFileImageIntroduction?fileNameId=${fileName}`;
  },
  DeleteContactAndIntroductionByList: (
    listId: string[]
  ): Promise<ListRespone<ContactAndIntroduction>> => {
    const url = `/ContactAndIntroduction/DeleteContactAndIntroductionByList`;
    return axiosClient.delete(url, { data: listId });
  },
  ActiveContactAndIntroductionByList: (
    listId: string[],
    isActive: boolean
  ): Promise<ListRespone<ContactAndIntroduction>> => {
    const url = `/ContactAndIntroduction/ActiveContactAndIntroductionByList?isActive=${isActive}`;
    return axiosClient.put(url, listId);
  },
} as const;
