import { globalVariable } from "../../../globalVariable";
import { ListRespone, ListResponeFormData } from "../../../models/commom";
import { Slide } from "../../../models/slide";
import { axiosClient, axiosClientFormData } from "./axiosClient";

export const SlideApis = {
  GetListSlideAdmin: (
    pageSize: number,
    pageNumber: number
  ): Promise<ListRespone<Slide>> => {
    const url = `/Slide/GetListSlideAdmin?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListSlideClient: (
    pageSize: number,
    pageNumber: number
  ): Promise<ListRespone<Slide>> => {
    const url = `/Slide/GetListSlideClient?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListSlideById: (IdSlide: string): Promise<ListRespone<Slide>> => {
    const url = `/Slide/GetListSlideById?IdSlide=${IdSlide}`;
    return axiosClient.get(url);
  },
  InsertSlide: (slide: Partial<Slide>): Promise<ListResponeFormData> => {
    const url = `/Slide/InsertSlide`;
    return axiosClientFormData.post(url, slide);
  },
  UpdateSlide: (slide: Partial<Slide>): Promise<ListResponeFormData> => {
    const url = `/Slide/UpdateSlide`;
    return axiosClientFormData.put(url, slide);
  },
  HideSlide: (IdSlide: string, check: boolean): Promise<ListRespone<Slide>> => {
    const url = `/Slide/HideSlide?IdSlide=${IdSlide}&check=${check}`;
    return axiosClient.put(url);
  },
  DeleteSlide: (IdSlide: string): Promise<ListRespone<Slide>> => {
    const url = `/Slide/DeleteSlide?IdSlide=${IdSlide}`;
    return axiosClient.delete(url);
  },
  GetFileSlide: (fileName: string) => {
    return `${globalVariable.urlServerApi}/api/Slide/GetFileSlide?fileNameId=${fileName}`;
  },
} as const;
