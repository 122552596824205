import { axiosClient } from "./axiosClient";
import { ListRespone } from "../../../models/commom";
import { MenuDto } from "../../../models/MenuDto";

export const MenuApis = {
  GetListMenuClient: (pageSize: number, pageNumber: number): Promise<ListRespone<MenuDto>> => {
    const url = `/Menu/GetListMenuClient?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListMenuAdmin: (pageSize: number, pageNumber: number): Promise<ListRespone<MenuDto>> => {
    const url = `/Menu/GetListMenuAdmin?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetMenuById: (IdMenu: string): Promise<ListRespone<MenuDto>> => {
    const url = `/Menu/GetMenuById?IdMenu=${IdMenu}`;
    return axiosClient.get(url);
  },
  UpdateMenu: (menu: Partial<MenuDto>): Promise<ListRespone<MenuDto>> => {
    const url = `/Menu/UpdateMenu`;
    return axiosClient.put(url, menu);
  },
  HideMenuByList: (listId: string[], IsHide: boolean): Promise<ListRespone<MenuDto>> => {
    const url = `/Menu/HideMenuByList?IsHide=${IsHide}`;
    return axiosClient.put(url, listId);
  },
} as const;