import { Fragment, lazy, Suspense } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../package/client/components/footer";

import { Header } from "../package/client/components/header";
import Loading from "../units/loading";
import { Products } from "../package/client/features/products/page/Products";
import { ProductCategory } from "../package/client/features/products/page/ProductCategory";
import { ProductDetail } from "../package/client/features/products/page/ProductDetail";
// import { AboutUsDetail } from "../package/client/features/aboutUs/page/AboutUsDetail";
// import { BlogCategory } from "../package/client/features/blog/page/BlogCategory";
// import { ServiceCategory } from "../package/client/features/services/page/ServiceCategory";
// import { AboutUS } from "../package/client/features/aboutUs/page/AboutUS";
// import { Blog } from "../package/client/features/blog/page/Blog";
// import { BlogDetail } from "../package/client/features/blog/page/BlogDetail";
// import { Career } from "../package/client/features/career/page/Career";
// import { ContactUs } from "../package/client/features/contactUs/page/ContactUs";
// import { HomePage } from "../package/client/features/homePage/page/HomePage";
// import { Services } from "../package/client/features/services/page/Services";
// import { ServicesDetail } from "../package/client/features/services/page/ServicesDetail";
const ServiceCategory = lazy(() =>
  import("../package/client/features/services/page/ServiceCategory").then((module) => ({ default: module.ServiceCategory })),
);
const BlogCategory = lazy(() =>
  import("../package/client/features/blog/page/BlogCategory").then((module) => ({ default: module.BlogCategory })),
);
const AboutUsDetail = lazy(() =>
  import("../package/client/features/aboutUs/page/AboutUsDetail").then((module) => ({ default: module.AboutUsDetail })),
);
const AboutUS = lazy(() => import("../package/client/features/aboutUs/page/AboutUS").then((module) => ({ default: module.AboutUS })));
const Blog = lazy(() => import("../package/client/features/blog/page/Blog").then((module) => ({ default: module.Blog })));
const BlogDetail = lazy(() =>
  import("../package/client/features/blog/page/BlogDetail").then((module) => ({ default: module.BlogDetail })),
);
const Career = lazy(() => import("../package/client/features/career/page/Career").then((module) => ({ default: module.Career })));
const ContactUs = lazy(() =>
  import("../package/client/features/contactUs/page/ContactUs").then((module) => ({ default: module.ContactUs })),
);
const HomePage = lazy(() =>
  import("../package/client/features/homePage/page/HomePage").then((module) => ({ default: module.HomePage })),
);
const Services = lazy(() =>
  import("../package/client/features/services/page/Services").then((module) => ({ default: module.Services })),
);
const ServicesDetail = lazy(() =>
  import("../package/client/features/services/page/ServicesDetail").then((module) => ({ default: module.ServicesDetail })),
);
const styleLoading = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export function Client() {
  const navigate = useNavigate();
  const path = useLocation();
  return (
    <Fragment>
      <Suspense
        fallback={
          <div style={styleLoading}>
            <Loading />
          </div>
        }
      >
        <div className="page">
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUS />} />
            <Route path="/about-us-detail-:id" element={<AboutUsDetail />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services-category-:id" element={<ServiceCategory />} />
            <Route path="/services-detail-:id" element={<ServicesDetail />} />
            <Route path="/product" element={<Products />} />
            <Route path="/product-category-:id" element={<ProductCategory />} />
            <Route path="/product-detail-:id" element={<ProductDetail />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog-category-:id" element={<BlogCategory />} />
            <Route path="/blog-detail-:idNew" element={<BlogDetail />} />
            <Route path="/Career" element={<Career />} />
            <Route path="/Contact-us" element={<ContactUs />} />
          </Routes>
          <Footer />
        </div>
      </Suspense>
    </Fragment>
  );
}
