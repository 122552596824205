import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListRespone } from "../models/commom";
import { InforProduction } from "../models/InforProduction";
import { InforProductionApis } from "../package/client/api/inforProductApis";

interface InforProductSliceState {
  loading: boolean;
  apis: ListRespone<InforProduction>;
  btnLoading: boolean;
  postLength: number;
}

const initialState: InforProductSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as InforProduction,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListInforProductClient = createAsyncThunk(
  "InforProduct/GetListInforProductClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await InforProductionApis.GetListInforProductionClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetInforProductByIdCategoriesProduct = createAsyncThunk(
  "InforProduct/GetInforProductByIdCategoriesProduct",
  async (params: { idCategoriesProduct: string }, thunkAPI) => {
    try {
      const response = await InforProductionApis.GetInforProductionByIdCategoriesService(params.idCategoriesProduct);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetListInforProductByIdCategoriesProduct = createAsyncThunk(
  "InforProduct/GetListInforProductByIdCategoriesProduct",
  async (params: { idCategoriesProduct: string; pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await InforProductionApis.GetListInforProductionByIdCategoriesService(
        params.pageSize,
        params.pageNumber,
        params.idCategoriesProduct,
      );
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetListInforProductById = createAsyncThunk(
  "InforProduct/GetListInforProductById",
  async (params: { id: string }, thunkAPI) => {
    try {
      const response = await InforProductionApis.GetListInforProductionById(params.id);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const InforProductSlice = createSlice({
  name: "InforProduct",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListInforProductClient
    [GetListInforProductClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListInforProductClient.fulfilled.type]: (state, action: PayloadAction<ListRespone<InforProduction>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListInforProductClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetInforProductByIdCategoriesProduct
    [GetInforProductByIdCategoriesProduct.pending.type]: (state) => {
      state.loading = true;
    },
    [GetInforProductByIdCategoriesProduct.fulfilled.type]: (state, action: PayloadAction<ListRespone<InforProduction>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetInforProductByIdCategoriesProduct.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListInforProductByIdCategoriesProduct
    [GetListInforProductByIdCategoriesProduct.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListInforProductByIdCategoriesProduct.fulfilled.type]: (state, action: PayloadAction<ListRespone<InforProduction>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListInforProductByIdCategoriesProduct.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListInforProductById
    [GetListInforProductById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListInforProductById.fulfilled.type]: (state, action: PayloadAction<ListRespone<InforProduction>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListInforProductById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
  },
});

export default InforProductSlice.reducer;
