import axios from "axios";
import { globalVariable } from "../../../globalVariable";
import { getCookie } from "../../../units";

export const axiosClient = axios.create({
  baseURL: `${globalVariable.urlServerApi}/api`,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getCookie("jwt")}`,
  },
});

export const axiosClientFormData = axios.create({
  baseURL: `${globalVariable.urlServerApi}/api`,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${getCookie("jwt")}`,
  },
});

export const axiosClientDownload = axios.create({
  baseURL: `${globalVariable.urlServerApi}/api`,
  headers: {
    "Content-Type": "application/octet-stream",
    Authorization: `Bearer ${getCookie("jwt")}`,
  },
  responseType: "blob",
});

axiosClient.interceptors.request.use(async (config) => {
  // Handle token here ...
  return config;
});
axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    // Handle errors
    throw error;
  }
);
