import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListRespone } from "../models/commom";
import { Contact } from "../models/contact";
import { ContactAndIntroduction } from "../models/contactAndIntroduction";
import { News } from "../models/news";
import { ContactApis } from "../package/client/api/contactApis";
import { NewsApis } from "../package/client/api/newsApis";

interface ContactSliceState {
  loading: boolean;
  apis: ListRespone<Contact>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: ContactSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as Contact,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListContactClient = createAsyncThunk(
  "Contact/GetListContactClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await ContactApis.GetListContactClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const InsertContact = createAsyncThunk("Contact/InsertContact", async (params: Partial<Contact>, thunkAPI) => {
  try {
    const response = await ContactApis.InsertContact(params);
    if (response.success) {
      return thunkAPI.fulfillWithValue(response);
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

const ContactSlice = createSlice({
  name: "Contact",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListContactClient
    [GetListContactClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListContactClient.fulfilled.type]: (state, action: PayloadAction<ListRespone<Contact>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListContactClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region InsertContact
    [InsertContact.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [InsertContact.fulfilled.type]: (state, action: PayloadAction<ListRespone<Contact>>) => {
      state.btnLoading = false;
    },
    [InsertContact.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
  },
});

export default ContactSlice.reducer;
