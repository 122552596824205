import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import "./index.css";
import image from "./ErrorLampRobot.png";

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}): JSX.Element {
  const navigate = useNavigate();
  return (
    <div className='error-boundary-container '>
      <div className='error-boundary'>
        <img
          src={image}
          alt='error'
          className='error-boundary-image'
        />
      </div>
      <div className='error-boundary-content'>
        <h1 className='featured-title'>Something went wrong</h1>
        <p className='featured-desc'>
          We're working on getting this fixed as soon as we can. You can try
          refreshing the page. If the problem persists feel free to contact us.
        </p>
        <button
          className='ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-icon-btn-left ttm-btn-color-blue'
          onClick={() => {
            resetErrorBoundary();
            navigate("/");
          }}
        >
          Go to Home
        </button>
      </div>
    </div>
  );
}

export default function WithErrorBoundaryCustom<T>(component: React.FC<T>) {
  return withErrorBoundary(component, {
    FallbackComponent: ErrorFallback,
  });
}
