import { aboutUs } from "../../../models/aboutUs";
import { ListRespone } from "../../../models/commom";
import { axiosClient } from "./axiosClient";

export const aboutUsApis = {
  GetAboutUsByIdCategorieAboutUs: (IdCategorieAboutUs: string): Promise<ListRespone<aboutUs>> => {
    const url = `/AboutUs/GetAboutUsByIdCategorieAboutUs?IdCategorieAboutUs=${IdCategorieAboutUs}`;
    return axiosClient.get(url);
  },
  GetListAboutUsClient: (pageSize: number, pageNumber: number): Promise<ListRespone<aboutUs>> => {
    const url = `/AboutUs/GetListAboutUsClient?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListAboutUsClientMain: (pageSize: number, pageNumber: number): Promise<ListRespone<aboutUs>> => {
    const url = `/AboutUs/GetListAboutUsClientMain?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },

} as const;
