import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Career } from "../models/career";
import { ListRespone } from "../models/commom";
import { ContactAndIntroduction } from "../models/contactAndIntroduction";
import { News } from "../models/news";
import { CareerApis } from "../package/client/api/careerApis";
import { NewsApis } from "../package/client/api/newsApis";

interface CareerSliceState {
  loading: boolean;
  apis: ListRespone<Career>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: CareerSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as Career,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListCareerClient = createAsyncThunk(
  "Career/GetListCareerClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CareerApis.GetListCareerClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const CareerSlice = createSlice({
  name: "Career",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListCareerClient
    [GetListCareerClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCareerClient.fulfilled.type]: (state, action: PayloadAction<ListRespone<Career>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCareerClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
  },
});

export default CareerSlice.reducer;
