import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListRespone } from "../models/commom";
import { CorporatePartner } from "../models/corporatePartner";
import { LeaderShip } from "../models/leaderShip";
import { LeaderShipApis } from "../package/client/api/leaderShipApis";

interface LeaderShipSliceState {
  loading: boolean;
  apis: ListRespone<LeaderShip>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: LeaderShipSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as LeaderShip,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListLeadershipClient = createAsyncThunk(
  "LeaderShip/GetListLeadershipClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await LeaderShipApis.GetListLeadershipClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const LeaderShipSlice = createSlice({
  name: "LeaderShip",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListLeadershipClient
    [GetListLeadershipClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListLeadershipClient.fulfilled.type]: (state, action: PayloadAction<ListRespone<LeaderShip>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListLeadershipClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
  },
});

export default LeaderShipSlice.reducer;
