import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListRespone } from "../models/commom";
import { CorporatePartner } from "../models/corporatePartner";
import { CorporatePartnerApis } from "../package/client/api/corporatePartnerApis";

interface CorporatePartnerSliceState {
  loading: boolean;
  apis: ListRespone<CorporatePartner>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: CorporatePartnerSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as CorporatePartner,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListCorporatePartnerClient = createAsyncThunk(
  "CorporatePartner/GetListCorporatePartnerClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CorporatePartnerApis.GetListCorporatePartnerClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const CorporatePartnerSlice = createSlice({
  name: "CorporatePartner",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListCorporatePartnerClient
    [GetListCorporatePartnerClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCorporatePartnerClient.fulfilled.type]: (state, action: PayloadAction<ListRespone<CorporatePartner>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCorporatePartnerClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
  },
});

export default CorporatePartnerSlice.reducer;
