import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoryAboutUs } from "../models/categoryAboutUs";
import { ListRespone } from "../models/commom";
import { categoryAboutUsApis } from "../package/client/api/categoryAboutUsApis";

interface CategoryAboutUsSliceState {
  loading: boolean;
  apis: ListRespone<CategoryAboutUs>;
  apisWithAppBar: ListRespone<CategoryAboutUs>;
  btnLoading: boolean;
  postLength: number;
}

const initialState: CategoryAboutUsSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as CategoryAboutUs,
  },
  btnLoading: false,
  postLength: 0,

  apisWithAppBar: {
    listPayload: [],
    payload: {} as CategoryAboutUs,
  },
};
export const GetListCategoryAboutUsClient = createAsyncThunk(
  "CategoryAboutUs/GetListCategoryAboutUsClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await categoryAboutUsApis.GetListCategoryAboutUsClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetListCategoryAboutUsAppBar = createAsyncThunk(
  "CategoryAboutUs/GetListCategoryAboutUsAppBar",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await categoryAboutUsApis.GetListCategoryAboutUsAppBar(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  });

const CategoryAboutUsSlice = createSlice({
  name: "CategoryAboutUs",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListCategoryAboutUsClient
    [GetListCategoryAboutUsClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryAboutUsClient.fulfilled.type]: (state, action: PayloadAction<ListRespone<CategoryAboutUs>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoryAboutUsClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListCategoryAboutUsAppBar
    [GetListCategoryAboutUsAppBar.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryAboutUsAppBar.fulfilled.type]: (state, action: PayloadAction<ListRespone<CategoryAboutUs>>) => {
      state.loading = false;
      state.apisWithAppBar = action.payload;
    },
    [GetListCategoryAboutUsAppBar.rejected.type]: (state) => {
      state.loading = false;
    },
  },
});

export default CategoryAboutUsSlice.reducer;
