import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { GetListCategoryProductClient } from "../../../../../app/CategoryProductsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { ContactAndIntroductionApis } from "../../../api/contactAndIntroductionApis";
import { PageTiltle } from "../../../components/pageTille";
import { useTranslation } from "react-i18next";

function _Products() {
  const { t } = useTranslation(["product"]);
  const dispatch = useAppDispatch();
  const [postLength, setPostLength] = useState(6);
  const { loading, apis } = useAppSelector((state) => state.CategoryProducts);
  useEffect(() => {
    document.title = t("product:Title");
  }, [t]);
  useEffect(() => {
    dispatch(GetListCategoryProductClient({ pageNumber: 0, pageSize: postLength }));
  }, [dispatch, postLength]);

  return (
    <div className="Products">
      <PageTiltle title={t("product:Title")} subTitle={t("product:Title")} breadcrumb={t("product:HomeLink")}
                  breadcrumbLink="/" />
      <div className="site-main">
        <section className="ttm-row grid-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h5>{t("product:OurProduct")}</h5>
                    <h2 className="title">
                      {t("product:Describe")} <span>{t("product:Describe2")}</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {loading
                ? Array(6)
                  .fill(0)
                  .map((item, index) => (
                    <div className="col-md-6 col-lg-4" key={index}>
                      <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                        <div className="featured-thumbnail">
                          <img className="img-fluid" src={"images/services/01.jpg"} alt="image" />
                        </div>
                        <div className="featured-content box-shadow">
                          <div className="featured-title" style={{ height: 62 }}>
                            <h5>
                              <Skeleton count={1} />
                            </h5>
                          </div>
                          <div className="featured-desc">
                            <p>
                              <Skeleton count={5} />
                            </p>
                          </div>
                          <Link
                            className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                            to={`/product-category-${item.id}`}
                          >
                            {t("product:ReadMore")} <i className="ti ti-angle-double-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                : apis.listPayload.map((item) => (
                  <div className="col-md-6 col-lg-4" key={item.id}>
                    <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                      <div className="featured-thumbnail">
                        <img
                          className="img-fluid"
                          src={
                            item.fileName
                              ? ContactAndIntroductionApis.GetFileContactAndIntroduction(item.id + "." + item.fileNameExtention)
                              : "images/logoBigSize.jpg"
                          }
                          style={{ height: 270 }}
                          alt="image"
                        />
                      </div>
                      <div className="featured-content box-shadow">
                        <div
                          className="featured-title"
                          style={{
                            height: 50,
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            marginBottom: 10,
                          }}
                          data-tooltip={item.nameProduct}
                        >
                          <h5>
                            <Link to={`/product-category-${item.id}`}>{item.nameProduct}</Link>
                          </h5>
                        </div>
                        <div
                          className="featured-desc"
                          style={{
                            height: 75,
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                          }}
                          data-tooltip={item.description}
                        >
                          <p>{item.description}</p>
                        </div>
                        <Link
                          className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                          to={`/product-category-${item.id}`}
                        >
                          {t("product:ReadMore")} <i className="ti ti-angle-double-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                {apis.totalElament && postLength <= apis.totalElament && (
                  <button
                    className="ttm-btn ttm-btn-size-md ttm-btn-style-border  ttm-btn-color-blue"
                    onClick={() => {
                      // navigate("/");
                      setPostLength(postLength + 6);
                    }}
                  >
                    {t("product:SeeMore")}
                  </button>
                )}

                {/* <div className="ttm-pagination">
                  <span className="page-numbers current">1</span>
                  <a className="page-numbers" href="#">
                    2
                  </a>
                  <a className="next page-numbers" href="#">
                    <i className="ti ti-arrow-right"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export const Products = WithErrorBoundaryCustom(_Products);
