import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { aboutUs } from "../models/aboutUs";
import { ListRespone } from "../models/commom";
import { aboutUsApis } from "../package/client/api/aboutUsApis";

interface AboutUsSliceState {
  loading: boolean;
  apis: ListRespone<aboutUs>;
  apisMain: ListRespone<aboutUs>;
  btnLoading: boolean;
  postLength: number;
}

const initialState: AboutUsSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as aboutUs,
  },
  apisMain: {
    listPayload: [],
    payload: {} as aboutUs,
  },
  btnLoading: false,
  postLength: 0,
};

export const GetInforAboutUsByIdCategoriesAboutUs = createAsyncThunk(
  "AboutUs/GetInforAboutUsByIdCategoriesAboutUs",
  async (params: { idCategoriesAboutUs: string }, thunkAPI) => {
    try {
      const response = await aboutUsApis.GetAboutUsByIdCategorieAboutUs(params.idCategoriesAboutUs);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetListAboutUsClient = createAsyncThunk("AboutUs/GetListAboutUsClient", async (params: { pageSize: number, pageNumber: number }, thunkAPI) => {
  try {
    const response = await aboutUsApis.GetListAboutUsClient(params.pageSize, params.pageNumber);
    if (response.success) {
      return thunkAPI.fulfillWithValue(response);
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const GetListAboutUsClientMain = createAsyncThunk("AboutUs/GetListAboutUsClientMain", async (params: { pageSize: number, pageNumber: number }, thunkAPI) => {
  try {
    const response = await aboutUsApis.GetListAboutUsClientMain(params.pageSize, params.pageNumber);
    if (response.success) {
      return thunkAPI.fulfillWithValue(response);
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

const AboutUsSlice = createSlice({
  name: "AboutUs",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetInforAboutUsByIdCategoriesAboutUs
    [GetInforAboutUsByIdCategoriesAboutUs.pending.type]: (state) => {
      state.loading = true;
    },
    [GetInforAboutUsByIdCategoriesAboutUs.fulfilled.type]: (state, action: PayloadAction<ListRespone<aboutUs>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetInforAboutUsByIdCategoriesAboutUs.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion

    //#region GetListAboutUsClient
    [GetListAboutUsClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListAboutUsClient.fulfilled.type]: (state, action: PayloadAction<ListRespone<aboutUs>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListAboutUsClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListAboutUsClientMain
    [GetListAboutUsClientMain.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListAboutUsClientMain.fulfilled.type]: (state, action: PayloadAction<ListRespone<aboutUs>>) => {
      state.loading = false;
      state.apisMain = action.payload;
    },
    [GetListAboutUsClientMain.rejected.type]: (state) => {
      state.loading = false;
    },
  },
});

export default AboutUsSlice.reducer;
